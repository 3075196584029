import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import {
  QueryGroup,
  QueryItem,
  logicalOperator,
} from '@clean-code/feature/query-builder/domain';
import {
  QueryItemForm,
  QueryItemInputForm,
} from '../query-builder-item/query-builder-item-form';

export interface QueryGroupForm {
  operator: FormControl<logicalOperator>;
  groups: FormArray<FormGroup<QueryGroupForm>>;
  items: FormArray<FormGroup<QueryItemForm>>;
}

export class QueryGroupInputForm extends FormGroup<QueryGroupForm> {
  constructor(
    readonly model?: QueryGroup,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group<QueryGroupForm>({
        operator: new FormControl<logicalOperator>('&&', [Validators.required]),
        groups: new FormArray<FormGroup<QueryGroupForm>>([]),
        items: new FormArray<FormGroup<QueryItemForm>>([]),
      }).controls
    );

    (this.get('groups') as UntypedFormArray).clear();
    (this.get('items') as UntypedFormArray).clear();

    if (model) {
      this.patchValue(model);

      model.groups?.forEach((element: QueryGroup) => {
        (this.get('groups') as UntypedFormArray).push(
          new QueryGroupInputForm(element)
        );
      });
      model.items?.forEach((element: QueryItem) => {
        (this.get('items') as UntypedFormArray).push(
          new QueryItemInputForm(element)
        );
      });
    } else {
      this.patchValue({});

      (this.get('items') as UntypedFormArray).push(new QueryItemInputForm());
    }
  }
}
