export const queryItemFragment = `fragment ItemsFragment on QueryItem {
        operator
        
        identifierGroup
        identifierType
        identifier
        {
            ...IdentifierFragment
        }
        value
        {
            value
            type
            identifierGroup
            identifierType
            identifier
            {
                ...IdentifierFragment
            }
        }
}`;

export const queryIdentifierFragment = `fragment IdentifierFragment on TimeSeriesObjectType {
        identifierId
        adjustment
        identifier
      }`;

export const queryGroupFragment = `fragment GroupFragment on QueryGroup {
      operator

      items {
        ...ItemsFragment
      }
    }`;

export const queryGroupFragmentRecursive = `fragment GroupRecursive on QueryGroup {
    ...GroupFragment
    groups {
      ...GroupFragment
      groups {
        ...GroupFragment
        groups {
          ...GroupFragment
        }
      }
    }
  }`;

export const queryConfigurationFragment = `fragment ConfigurationFragment on QueryConfiguration {
    group
    {
        ...GroupRecursive
    }
  }`;
