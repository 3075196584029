import { Inject, Injectable } from '@angular/core';
import {
  TimeSeriesAnalysisIdentifierFormFacade,
  TimeSeriesIdentifierCategory,
} from '@backoffice-frontend/time-series-identifier/api';
import { DataTableParameters, ID } from '@clean-code/shared/common';
import { EditSearchDataService } from '@clean-code/shared/util/util-data-service';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { UserLimit } from '../entities/user-limit';
import { USER_LIMIT_SERVICE } from '../infrastructure/user-limit-service-token';
import { UserLimitService } from '../infrastructure/user-limit.service';
@Injectable({ providedIn: 'root' })
export class UserLimitAdminFormFacade
  implements EditSearchDataService<UserLimit>
{
  constructor(
    @Inject(USER_LIMIT_SERVICE) protected service: UserLimitService,
    private tsFormFacade: TimeSeriesAnalysisIdentifierFormFacade
  ) {}

  getById$(id: ID): Observable<UserLimit> {
    return this.service.getById$(id);
  }

  getAll$(
    _params: DataTableParameters
  ): Observable<PaginationResponse<UserLimit>> {
    throw new Error('Method not implemented.');
  }

  getTimeSeriesIdentifierCategoriesLocalized$(): Observable<
    TimeSeriesIdentifierCategory[]
  > {
    return this.tsFormFacade.getTimeSeriesIdentifierCategoriesLocalized$();
  }

  getTimeSeriesIdentifierDataByCategory$(categoryId: ID) {
    return this.tsFormFacade.getTimeSeriesIdentifierDataByCategory$(categoryId);
  }

  add$(entity: UserLimit): Partial<Observable<boolean | UserLimit | ID>> {
    return this.service.add$(entity);
  }
  update$(entity: UserLimit): Partial<Observable<boolean | UserLimit | ID>> {
    return this.service.update$(entity);
  }
  delete$(id: ID): Partial<Observable<boolean | void>> {
    throw new Error('Method not implemented.');
  }
}
