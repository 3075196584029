import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TimeSeriesIdentifierInputForm } from '@backoffice-frontend/time-series-identifier/api';
import {
  QueryValue,
  sourceType,
  valueType,
} from '@clean-code/feature/query-builder/domain';
import { ID } from '@clean-code/shared/common';

export interface QueryValueForm {
  value: FormControl<number>;
  identifier: UntypedFormGroup;
  type: FormControl<valueType>;
  identifierGroup: FormControl<ID>;
  identifierType: FormControl<sourceType>;
}

export class QueryValueInputForm extends FormGroup<QueryValueForm> {
  constructor(
    readonly model?: QueryValue,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group<QueryValueForm>({
        value: new FormControl<number>(null),
        identifier: new TimeSeriesIdentifierInputForm(),
        type: new FormControl<valueType>('FIX_VALUE'),
        identifierGroup: new FormControl<ID>(null),
        identifierType: new FormControl<sourceType>(null, [
          Validators.required,
        ]),
      }).controls
    );

    if (model) {
      this.patchValue(model);
    }
  }
}
