import { Injectable, inject } from '@angular/core';
import {
  TimeSeriesAnalysisIdentifierFormFacade,
  TimeSeriesIdentifierCategory,
} from '@backoffice-frontend/time-series-identifier/api';
import { ID } from '@clean-code/shared/common';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QueryBuilderFacade {
  private tsFormFacade = inject(TimeSeriesAnalysisIdentifierFormFacade);

  getTimeSeriesIdentifierCategoriesLocalized$(): Observable<
    TimeSeriesIdentifierCategory[]
  > {
    return this.tsFormFacade.getTimeSeriesIdentifierCategoriesLocalized$();
  }

  getTimeSeriesIdentifierDataByCategory$(categoryId: ID) {
    return this.tsFormFacade.getTimeSeriesIdentifierDataByCategory$(categoryId);
  }
}
