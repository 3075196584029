import { Injectable } from '@angular/core';
import { DataTableParameters, SortBy } from '@clean-code/shared/common';
import { PaginationResponse } from '@datorama/akita';
import { Observable } from 'rxjs';
import { UserLimit } from '../entities/user-limit';
import { UserLimitServiceBase } from './user-limit-base.service';

@Injectable({
  providedIn: 'root',
})
export class UserLimitAdminService extends UserLimitServiceBase {
  protected override queryName = 'userLimitsAdmin';
  protected override mutationName = 'userLimitAdmin';

  override getAll$(
    params: DataTableParameters,
  ): Observable<PaginationResponse<UserLimit>> {
    const param = this.graphqlService.mapDataTableParameters(params);
    const where = this.graphqlService.mapDataTableFilterParameters(
      params.filters,
    );

    const sortBy: SortBy[] = params.sortBy === null ? null : [];

    params.sortBy.forEach((value) => {
      if (value.prop === 'creator') {
        //change to firstName (fullName does not exist on db)
        value.prop = 'creator.firstName';
        //push lastName
        sortBy.push({ prop: 'creator.lastName', dir: value.dir });
      } else if (value.prop === 'modifier') {
        //change to firstName (fullName does not exist on db)
        value.prop = 'modifier.firstName';
        //push lastName
        sortBy.push({ prop: 'modifier.lastName', dir: value.dir });
      }
      sortBy.push(value);
    });

    const order = this.graphqlService.mapDataTableSortParameters(sortBy);

    return this.getAllInternal$(
      param,
      order,
      where,
      params.page,
      params.perPage,
    );
  }

  constructor() {
    super();
    this.entityFields =
      this.entityFields +
      `
    createdBy
    `;
  }
}
