import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TimeSeriesIdentifierInputForm } from '@backoffice-frontend/time-series-identifier/domain';
import {
  QueryItem,
  comparisonOperator,
  sourceType,
} from '@clean-code/feature/query-builder/domain';
import { ID } from '@clean-code/shared/common';
import {
  QueryValueForm,
  QueryValueInputForm,
} from '../query-builder-item-value/query-builder-item-value-form';

export interface QueryItemForm {
  identifierGroup: FormControl<ID>;
  identifier: UntypedFormGroup;
  operator: FormControl<comparisonOperator>;
  identifierType: FormControl<sourceType>;
  value: FormGroup<QueryValueForm>;
}

export class QueryItemInputForm extends FormGroup<QueryItemForm> {
  constructor(
    readonly model?: QueryItem,
    readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group<QueryItemForm>({
        identifier: new TimeSeriesIdentifierInputForm(model?.identifier),
        identifierType: new FormControl<sourceType>('TIME_SERIES', [
          Validators.required,
        ]),
        identifierGroup: new FormControl<ID>(null),
        operator: new FormControl<comparisonOperator>(null, [
          Validators.required,
        ]),
        value: new QueryValueInputForm(model?.value),
      }).controls
    );

    if (model && !model.identifierType) {
      model.identifierType = 'TIME_SERIES';
    }

    if (model) {
      this.patchValue(model);
    }
  }
}
